import { RetainQueryLinkHref } from '@/components/RetainQueryLink'

export const LINKS = {
  NOT_CLIENT_PHONE: 'tel:30049137',
  NOT_CLIENT_WHATSAPP: {
    pathname: 'https://api.whatsapp.com/send',
    query: {
      phone: '551130049136',
      text: 'Oi! Eu gostaria de pedir uma maquininha. Você pode me auxiliar?'
    }
  },
  CLIENT_WHATSAPP: {
    pathname: 'https://api.whatsapp.com/send',
    query: {
      phone: '551130049136',
      text: 'Oi Ton, preciso de ajuda.'
    }
  },
  APP_LINK: 'https://app.adjust.com/nn3niv6',
  APP_LINK_TAPTON_CARDS: 'https://app.adjust.com/15faft9j',
  APP_LINK_CONSIDERATION_CARDS: 'https://app.adjust.com/187jcl1j'
}

type LinksThatRetainUtms = {
  [key: string]: RetainQueryLinkHref
}

export const LINKS_THAT_RETAIN_UTMS: LinksThatRetainUtms = {
  APP_LINK_TAPTON_CARDS: {
    pathname: 'https://app.adjust.com/15faft9j'
  },
  APP_LINK: {
    pathname: 'https://app.adjust.com/nn3niv6',
    query: { campaign: 'ton' }
  },
  RENDA_EXTRA: {
    pathname: 'https://rendaextra.ton.com.br/',
    query: { utm_placement: 'renda_ton' }
  },
  RENDA_EXTRA_PREPRD: {
    pathname: 'https://rendaextra.pre-prd.ton.com.br',
    query: { utm_placement: 'renda_ton' }
  },
  RENDA_EXTRA_SDX: {
    pathname: 'https://rendaextra.sdx.ton.com.br',
    query: { utm_placement: 'renda_ton' }
  },
  RENDA_EXTRA_REGULATION: {
    pathname: 'https://rendaextraton.com.br/regulamento-renda-ton/'
  },
  RENDA_EXTRA_REGULATION_PROMOTION_1: {
    pathname:
      'https://rendaextraton.com.br/regulamento-indique-3-e-ganhe-r-100/'
  },
  HELP_CENTER: {
    pathname: 'https://ajuda.ton.com.br'
  },
  TRACK_ORDER: {
    pathname: 'https://rastreie.ton.com.br/orders'
  },
  MEGATON_REGULATION: {
    pathname: 'https://documentos.ton.com.br/regulamento-megaton-tier.pdf'
  },
  TONPRO_REGULATION: {
    pathname: `https://documentos.ton.com.br/regulamento-tonpro-tier.pdf`
  },
  TAPTON: {
    pathname: 'https://www.ton.com.br/tapton'
  }
}

export const CLOUDINARY_BASE_URL = 'https://res.cloudinary.com/dunz5zfpt'
