import { AllPlansType, AllTiersType, LiquidationsType } from './plans'

// Bandeiras de cartões de crédito e débito
export enum PaymentsCard {
  Mastercard = 'mastercard',
  Visa = 'visa',
  Elo = 'elo',
  Amex = 'amex',
  Hipercard = 'hipercard'
}

// Tipagem para as bandeiras de cartões de crédito e débito
export type PaymentsCardType = `${PaymentsCard}`

// Métodos de captura de transação com cartão
export enum CardTransaction {
  DebitCard = 'debit_card',
  CreditCard = 'credit_card'
}

// Tipagem para os métodos de captura de transação com cartão
export type CardTransactionType = `${CardTransaction}`

// Opções dos termos de antecipação
export enum ReceivingOptions {
  SameDay = 'same_day',
  OneBusinessDay = 'one_business_day',
  OneMonth = 'one_month'
}

// Tipagem para as opções dos termos de antecipação
export type ReceivingOptionsType = `${ReceivingOptions}`

// Tipagem das condições de antecipação
export type ReceivingOptionsConditionType = {
  [key in ReceivingOptionsType]: {
    anticipation_delay: number
    liquidation_type: LiquidationsType
  }
}

// Tipagem de informações comuns das opções de antecipação
export type ReceivingOptionsCommonInfoType = {
  [key in ReceivingOptionsType]: {
    label: string
  }
}

// Grupos de bandeiras de cartões
export enum FlagsGroup {
  main_flags = 'mainFlags',
  other_flags = 'otherFlags'
}

// Tipagem para os grupos de bandeiras de cartões
export type FlagsGroupType = `${FlagsGroup}`

// Tipagem dos grupos de bandeiras com suas bandeiras padrões
export type FlagsGroupCardsType = {
  [key in FlagsGroupType]: PaymentsCardType[]
}

// Tipagem das condições de taxas de desconto do comerciante
export interface FeesCondition {
  anticipation_delay: number
  mdrs: MerchantDiscountRates[]
  anticipation: number
  liquidation_type: LiquidationsType
}

// Tipagem das taxas de desconto do comerciante
export interface MerchantDiscountRates {
  card_brand: PaymentsCardType
  installments: Installment[]
  payment_method: CardTransactionType
}

// Tipagem das parcelas
export interface Installment {
  mdr: number
  installment: number
}

// Tipagem das informações dos planos e taxas disponíveis
export type PlanAndFeesInfo = {
  planName: AllPlansType
  tierName: AllTiersType
  commercialName: string
  description: string
  fees: InformationFeesDetails
}

// Tipagem das informações das taxas de desconto do comerciante
export type InformationFeesDetails = {
  [key in ReceivingOptionsType]: {
    [key in FlagsGroupType]: number[]
  }
}
