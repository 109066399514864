import React, { createContext, ReactNode, useContext } from 'react'

import { ReactVideoPlayer } from '@/components/ReactVideoPlayer'

type VideoPlayerContextType = {
  mountVideoPlayer: (url: string) => void
  unmountVideoPlayer: () => void
}

const VideoPlayerContextDefaultValues: VideoPlayerContextType = {
  mountVideoPlayer: () => undefined,
  unmountVideoPlayer: () => undefined
}

const VideoPlayerContext = createContext<VideoPlayerContextType>(
  VideoPlayerContextDefaultValues
)

export const useVideoPlayer = (): VideoPlayerContextType => {
  return useContext(VideoPlayerContext)
}

export const VideoPlayerProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [playerComponent, setPlayerComponent] = React.useState<ReactNode>(<></>)

  const mountVideoPlayer = (url: string) => {
    setPlayerComponent(
      <div className="flex fixed top-0 left-0 z-[1000] justify-center items-center w-full h-full bg-display-900/70">
        <button
          type="button"
          className="absolute top-0 right-0 z-50 p-24 text-20 text-display-0"
          onClick={setPlayerComponent.bind(null, <></>)}
        >
          X
        </button>

        <ReactVideoPlayer url={url} />
      </div>
    )
  }

  const unmountVideoPlayer = () => {
    setPlayerComponent(<></>)
  }

  return (
    <>
      <VideoPlayerContext.Provider
        value={{
          mountVideoPlayer,
          unmountVideoPlayer
        }}
      >
        <>
          {playerComponent}

          {children}
        </>
      </VideoPlayerContext.Provider>
    </>
  )
}
