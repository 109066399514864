interface Coupon {
  coupon: string
  referrer_id: string
  name: string
  tier_id: string
  custom_offer: string
  offer: Offer
}

interface Offer {
  value_type: string
  value: string
}

export async function fetchReferrerMetadata({
  coupon,
  deviceToken
}: {
  coupon: string
  deviceToken: string
}) {
  let response = {} as Coupon
  try {
    response = await fetch(
      `${process.env.NEXT_PUBLIC_REFERRERS_API}/referrers/${coupon}/metadata/public`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-device-token': deviceToken
        }
      }
    ).then((res) => res.json())
  } catch {
    return
  }

  return response
}
