import { useRouter } from 'next/router'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { getDebitFee, getSelectedCreditFee } from '@/helpers/fees'
import { planBBB24Config } from '@/resources/ton/plans'
import { Fee, Plan, PlanFee } from '@/types'
import {
  AllPlansForMachine,
  AVAILABLE_PLAN_TYPES,
  CardBrand,
  FeeCondition,
  ITapTon,
  LiquidationType,
  PlanTypeNameToPlan
} from '@/types/plans'

export type Fees = {
  [key in Plan]: Fee
}

export enum PAYMENT_TERM {
  SAME_DAY = 0,
  ONE_BUSINESS_DAY = 1,
  DAILY = 2,
  FOURTEEN_BUSINESS_DAY = 3,
  THIRTY_BUSINESS_DAY = 4
}

interface taptonFeeConditionProps {
  [key: number]: {
    anticipation_delay: number
    liquidation_type: LiquidationType
  }
}

const taptonFeeCondition: taptonFeeConditionProps = {
  [PAYMENT_TERM.SAME_DAY]: {
    anticipation_delay: 0,
    liquidation_type: 'daily' as LiquidationType
  },
  [PAYMENT_TERM.ONE_BUSINESS_DAY]: {
    anticipation_delay: 1,
    liquidation_type: 'business-day' as LiquidationType
  }
}

type InfoContextType = {
  plans: Plan[]
  plan: Plan
  fees: Fees
  allFees: Record<Plan, Fee[]>
  isMegaTon: boolean
  setPlan: (value: Plan) => void
  setIsMegaTon: (value: boolean) => void
  planFee: Fee
  promoFee: Fee
  promoAllFees: Fee[]
  taptonFee: Fee
  taptonAllFees: Fee[]
  tapton: ITapTon
  paymentTerm: PAYMENT_TERM
  setPaymentTerm: (value: PAYMENT_TERM) => void
}

const InfoContext = createContext<InfoContextType>({} as InfoContextType)

export function useInfo() {
  return useContext(InfoContext)
}

interface InfoProviderProps {
  planFees: PlanFee[]
  tapton: ITapTon
  children: ReactNode
}

const toLocaleOptions = {
  minimumFractionDigits: 2
}
const getFeesForCardBrand = (fees: FeeCondition, cardBrand: CardBrand) => [
  {
    1: `${getDebitFee(fees, cardBrand).toLocaleString(
      'pt-BR',
      toLocaleOptions
    )}%`
  },
  {
    1: `${getSelectedCreditFee(fees, 1, cardBrand).toLocaleString(
      'pt-BR',
      toLocaleOptions
    )}%`
  },
  {
    parcel: new Array(11).fill(2).map((i, parcela) => ({
      1: `${getSelectedCreditFee(fees, parcela + i, cardBrand).toLocaleString(
        'pt-BR',
        toLocaleOptions
      )}%`
    }))
  }
]

const DEFAULT_MAIN_CARD_BRAND = 'mastercard'
const DEFAULT_OTHER_CARD_BRAND = 'elo'

const plans: Plan[] = [
  AllPlansForMachine.Pro,
  AllPlansForMachine.Ultra,
  AllPlansForMachine.Mega,
  AllPlansForMachine.Super
]

export const InfoProvider: React.FC<InfoProviderProps> = ({
  children,
  planFees,
  tapton
}) => {
  const [plan, setPlan] = useState<Plan>(AllPlansForMachine.Super)
  const [paymentTerm, setPaymentTerm] = useState(PAYMENT_TERM.ONE_BUSINESS_DAY)
  const [isMegaTon, setIsMegaTon] = useState<boolean>(true)

  const fees = useMemo(() => {
    const combinedFees = {} as Record<Plan, Fee>

    for (const planType of AVAILABLE_PLAN_TYPES) {
      const planName = PlanTypeNameToPlan[planType] as Plan

      const planInfo = planFees.find((planTypeInfo) =>
        planName === AllPlansForMachine.Pro
          ? planTypeInfo.fees.name ===
            `${planBBB24Config.planPromoName}promo_abr24`
          : planTypeInfo.plan === planName
      )

      if (!planInfo) {
        throw new Error(`plain info not available for plan ${planName}`)
      }

      const planConditions = planInfo.fees.conditions[paymentTerm]

      const mainFlags = getFeesForCardBrand(
        planConditions,
        DEFAULT_MAIN_CARD_BRAND
      )

      const otherFlags = getFeesForCardBrand(
        planConditions,
        DEFAULT_OTHER_CARD_BRAND
      )

      combinedFees[planName] = {
        mainFlags,
        otherFlags
      }
    }

    return combinedFees
  }, [paymentTerm, planFees])

  const allFees = useMemo(() => {
    const combinedFees = {} as Record<Plan, Fee[]>

    for (const planType of AVAILABLE_PLAN_TYPES) {
      const planName = PlanTypeNameToPlan[planType] as Plan

      const planInfo = planFees.find((planTypeInfo) =>
        planName === AllPlansForMachine.Pro
          ? planTypeInfo.fees.name ===
            `${planBBB24Config.planPromoName}promo_abr24`
          : planTypeInfo.plan === planName
      )

      if (!planInfo) {
        throw new Error(`plain info not available for plan ${planName}`)
      }

      const planConditions = planInfo.fees.conditions.map((fee) => {
        if (!fee) {
          throw new Error('tapton missing condition')
        }
        const mainFlags = getFeesForCardBrand(fee, DEFAULT_MAIN_CARD_BRAND)
        const otherFlags = getFeesForCardBrand(fee, DEFAULT_OTHER_CARD_BRAND)

        return {
          mainFlags,
          otherFlags
        }
      })

      combinedFees[planName] = planConditions
    }

    return combinedFees
  }, [planFees])

  const promoFee = useMemo(() => {
    const planName = PlanTypeNameToPlan['promoton_tier_regular_abr24'] as Plan

    const planInfo = planFees.find(
      (planTypeInfo) =>
        planTypeInfo.fees.name ===
        `${planBBB24Config.planPromoName}regular_abr24`
    )

    if (!planInfo) {
      throw new Error(`plain info not available for plan ${planName}`)
    }

    const planConditions = planInfo.fees.conditions[paymentTerm]

    const mainFlags = getFeesForCardBrand(
      planConditions,
      DEFAULT_MAIN_CARD_BRAND
    )

    const otherFlags = getFeesForCardBrand(
      planConditions,
      DEFAULT_OTHER_CARD_BRAND
    )

    return {
      mainFlags,
      otherFlags
    }
  }, [planFees, paymentTerm])

  const promoAllFees = useMemo(() => {
    const planName = PlanTypeNameToPlan['promoton_tier_regular_abr24'] as Plan

    const planInfo = planFees.find(
      (planTypeInfo) =>
        planTypeInfo.fees.name ===
        `${planBBB24Config.planPromoName}regular_abr24`
    )

    if (!planInfo) {
      throw new Error(`plain info not available for plan ${planName}`)
    }

    return planInfo.fees.conditions.map((fee) => {
      if (!fee) {
        throw new Error('tapton missing condition')
      }
      const mainFlags = getFeesForCardBrand(fee, DEFAULT_MAIN_CARD_BRAND)
      const otherFlags = getFeesForCardBrand(fee, DEFAULT_OTHER_CARD_BRAND)

      return {
        mainFlags,
        otherFlags
      }
    })
  }, [planFees])

  const planFee = useMemo(() => fees[plan], [fees, plan])

  const taptonFee = useMemo(() => {
    const planConditions = tapton.conditions.find(
      (condition) =>
        condition.anticipation_delay ===
          taptonFeeCondition[paymentTerm].anticipation_delay &&
        condition.liquidation_type ===
          taptonFeeCondition[paymentTerm].liquidation_type
    )

    if (!planConditions) {
      throw new Error('tapton missing condition')
    }

    const mainFlags = getFeesForCardBrand(
      planConditions,
      DEFAULT_MAIN_CARD_BRAND
    )

    const otherFlags = getFeesForCardBrand(
      planConditions,
      DEFAULT_OTHER_CARD_BRAND
    )

    return {
      mainFlags,
      otherFlags
    }
  }, [tapton, paymentTerm])

  const taptonAllFees = useMemo(() => {
    return tapton.conditions.map((fee) => {
      if (!fee) {
        throw new Error('tapton missing condition')
      }
      const mainFlags = getFeesForCardBrand(fee, DEFAULT_MAIN_CARD_BRAND)
      const otherFlags = getFeesForCardBrand(fee, DEFAULT_OTHER_CARD_BRAND)

      return {
        mainFlags,
        otherFlags
      }
    })
  }, [tapton])

  const router = useRouter()

  useEffect(() => {
    switch (router?.query?.tax) {
      case 'select-0':
        setPlan(() => AllPlansForMachine.Super)
        break
      case 'select-1':
        setPlan(() => AllPlansForMachine.Ultra)
        break
      case 'select-2':
        setPlan(() => AllPlansForMachine.Pro)
        break
      case 'select-3':
        setPlan(() => AllPlansForMachine.Mega)
        break
      default:
        setPlan(() => AllPlansForMachine.Pro)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPlan, router?.query?.tax])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMegaTonChange = useCallback((value: any) => {
    setIsMegaTon(value)
  }, [])

  return (
    <>
      <InfoContext.Provider
        value={{
          plan,
          planFee,
          promoFee,
          setPlan,
          isMegaTon,
          setIsMegaTon: handleMegaTonChange,
          plans,
          fees,
          allFees,
          tapton,
          taptonFee,
          promoAllFees,
          taptonAllFees,
          paymentTerm,
          setPaymentTerm
        }}
      >
        {children}
      </InfoContext.Provider>
    </>
  )
}
