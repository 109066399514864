import { useQRCode } from 'next-qrcode'
import dynamic from 'next/dynamic'
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'

import Icon from '@/components/Icon'
import { LINKS } from '@/constants/links'
import cn from '@/utils/cn'
import { handleAppUtmUrl } from '@/utils/handleAppUtmUrl'

const ClientOnlyPortal = dynamic(() => import('../ClientOnlyPortal'), {
  ssr: false
})

type TQRCode = {
  isShowing: boolean
  onToggle: () => void
  isTapton?: boolean
}

const QRCode: React.FC<TQRCode> = ({
  isShowing,
  onToggle,
  isTapton = false
}) => {
  const { Canvas } = useQRCode()
  const modalRef = useRef<HTMLHeadingElement | null>(null)

  const [utmUrl, setUtmUrl] = useState(
    isTapton ? LINKS.APP_LINK_TAPTON_CARDS : LINKS.APP_LINK
  )

  const closeQRCodeModal = useCallback(
    (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      const contain = modalRef.current?.contains(event.target as Node)
      if (!contain) {
        onToggle()
        document.body.removeEventListener('click', () =>
          closeQRCodeModal(event)
        )
      }
    },
    [onToggle]
  )

  useEffect(() => setUtmUrl(handleAppUtmUrl(isTapton)), [isTapton])

  return (
    <ClientOnlyPortal selector="#drawer">
      <div
        className={cn(
          'fixed z-[10000] top-0 overflow-hidden h-screen bg-display-900 bg-opacity-25',
          {
            'w-0': !isShowing,
            'w-screen': isShowing
          }
        )}
        onClickCapture={(event: MouseEvent<HTMLDivElement>) =>
          closeQRCodeModal(event)
        }
      >
        <div
          className={cn(
            'flex relative flex-col justify-center items-center p-24 md:p-40 xs:ml-auto w-full max-w-[480px] h-full  bg-display-0 rounded-tl-2xl transition-all',
            {
              'transform-gpu translate-x-[100%]': !isShowing,
              'transform-gpu translate-x-0': isShowing
            }
          )}
          ref={modalRef}
        >
          <button
            type="button"
            aria-label="Botão de fechar"
            className=" flex absolute top-24 md:top-40 right-24 md:right-40 justify-center items-center p-8 md:w-40 md:h-40 bg-display-100 rounded-full"
            onClick={onToggle}
          >
            <Icon
              name="close"
              className="w-24 h-24 text-display-600 fill-current"
            />
          </button>
          <div className="flex">
            <Canvas
              text={utmUrl}
              options={{
                margin: 0,
                scale: 4,
                width: 136
              }}
            />
          </div>
          <p className="flex mt-24 font-heading font-bold text-center heading-3">
            Baixe o App do Ton e abra sua conta grátis
          </p>
          <p className="flex mt-16 text-center paragraph-18">
            Com a câmera do seu celular aberta, escaneie o QR Code acima e baixe
            o app Ton. Abra sua conta grátis e comece a vender!
          </p>
        </div>
      </div>
    </ClientOnlyPortal>
  )
}

export default QRCode
