import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'

import QRCode from '@/components/QRCode'
import { handleAppUtmUrl } from '@/utils/handleAppUtmUrl'

type QRCodeDrawerContextType = {
  toggleDrawer: () => void
  toggleTaptonDrawer?: () => void
  linkToDownloadApp: LinkToDownloadAppResponse
}

type LinkToDownloadAppResponse = {
  pathname: string
  query?: {
    [key: string]: string
  }
}

const QRCodeDrawerContextDefaultValues: QRCodeDrawerContextType = {
  toggleDrawer: () => undefined,
  toggleTaptonDrawer: () => undefined,
  linkToDownloadApp: {} as LinkToDownloadAppResponse
}

const QRCodeDrawerContext = createContext<QRCodeDrawerContextType>(
  QRCodeDrawerContextDefaultValues
)

export const useQRCodeDrawer = (): QRCodeDrawerContextType => {
  return useContext(QRCodeDrawerContext)
}

export const QRCodeDrawerProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [isTapton, setIsTapton] = useState<boolean>(false)
  const [linkToDownloadApp, setLinkToDownloadApp] = useState(
    {} as LinkToDownloadAppResponse
  )

  const toggleDrawer = () => {
    setIsTapton(false)
    setIsVisible((value) => !value)
  }

  const toggleTaptonDrawer = () => {
    setIsTapton(true)
    setIsVisible((value) => !value)
  }

  useEffect(() => {
    const linkToAppStr = handleAppUtmUrl(isTapton)
    const linkToAppObj = new URL(linkToAppStr)

    const linkToApp: LinkToDownloadAppResponse = {
      pathname: linkToAppObj.origin + linkToAppObj.pathname
    }

    const queryParams: Record<string, string> = {}
    const queryString = linkToAppObj.search.split('?')[1]

    if (queryString) {
      const params = queryString.split('&')
      params.forEach((param) => {
        const [key, value] = param.split('=')
        queryParams[key] = decodeURIComponent(value.replace(/\+/g, ' '))
      })

      linkToApp.query = queryParams
    }

    setLinkToDownloadApp(linkToApp)
  }, [isTapton])

  return (
    <>
      <QRCodeDrawerContext.Provider
        value={{
          toggleDrawer,
          toggleTaptonDrawer,
          linkToDownloadApp
        }}
      >
        <>
          <QRCode
            isShowing={isVisible}
            onToggle={toggleDrawer}
            isTapton={isTapton}
          />
          {children}
        </>
      </QRCodeDrawerContext.Provider>
    </>
  )
}
