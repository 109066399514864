/* eslint-disable camelcase */

import { LINKS } from '@/constants/links'

import { getAndSaveSearchParams } from './searchParams'

export const utmAdjustKeysMapper = {
  utm_campaign: 'campaign',
  utm_content: 'adgroup',
  utm_adname: 'creative'
}

const utmKeys = ['utm_campaign', 'utm_content', 'utm_adname'] as const

export const handleAppUtmUrl = (isTapton?: boolean): string => {
  getAndSaveSearchParams()

  const searchParams = JSON.parse(
    sessionStorage.getItem('searchParams') || '[]'
  )

  const queryData: { key: string; value: string }[] = Object.keys(
    searchParams
  ).map((key) => ({ key: key, value: searchParams[key] }))

  const urlWithQueryParams = utmKeys.reduce<string>(
    (previousValue, currentValue) => {
      const queryItem = queryData.find(({ key }) => key === currentValue)
      if (queryItem) {
        const queryKey = utmAdjustKeysMapper[currentValue]

        return `${previousValue}${previousValue ? '&' : '?'}${queryKey}=${
          queryItem.value
        }`
      }

      return previousValue
    },
    ''
  )

  const windowSearchParams = !!window.location.search
    ? `&${window.location.search.replace(/\?/g, '')}`
    : ''

  return urlWithQueryParams
    ? (isTapton ? LINKS.APP_LINK_TAPTON_CARDS : LINKS.APP_LINK) +
        urlWithQueryParams +
        windowSearchParams
    : `${isTapton ? LINKS.APP_LINK_TAPTON_CARDS : LINKS.APP_LINK}?campaign=ton`
}
