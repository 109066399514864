import ReactPlayer from 'react-player'

import { useVideoPlayer } from '@/contexts/videoPlayer'

interface ReactVideoPlayerProps {
  url: string
}
const AUTO_CLOSE_VIDEO_PLAYER_TIMER = 3000 // 3 seconds

export const ReactVideoPlayer = ({ url }: ReactVideoPlayerProps) => {
  const { unmountVideoPlayer } = useVideoPlayer()

  const autoCloseVideoPlayer = () => {
    setTimeout(() => {
      unmountVideoPlayer()
    }, AUTO_CLOSE_VIDEO_PLAYER_TIMER)
  }

  return (
    <ReactPlayer
      url={url}
      playing={true}
      width="90%"
      height="90%"
      onEnded={autoCloseVideoPlayer}
    />
  )
}
