const amplitudeExperimentsIds = {
  prod: {
    CRON_TON_031: 'prod-teste-ton-imagem-t3-cro-ton-031',
    CRON_TON_032: 'prod-teste-ton-familhao-cro-ton-032'
  },
  dev: {
    CRON_TON_031: 'sdx-teste-ton-imagem-t3-cro-ton-031',
    CRON_TON_032: 'sdx-teste-ton-familhao-cro-ton-032'
  }
} as const

const amplitudeFlagsIds = {
  prod: {
    FLAG_TON_WHATSAPP_AND_CHAT_WIDGET: 'flag-ton-widget-whatsapp',
    FLAG_TON_COUNTDOWNS: 'flag-ton-prod-countdowns'
  },
  dev: {
    FLAG_TON_WHATSAPP_AND_CHAT_WIDGET: 'flag-ton-widget-whatsapp',
    FLAG_TON_COUNTDOWNS: 'flag-ton-sdx-countdowns'
  }
} as const

const environment =
  process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === 'production' ? 'prod' : 'dev'

const TESTS = amplitudeExperimentsIds[environment]

const FLAGS = amplitudeFlagsIds[environment]

const AMPLITUDE = { TESTS, FLAGS }

export default AMPLITUDE
