import { RetainQueryLinkProps } from '@/components/RetainQueryLink'
import { LINKS_THAT_RETAIN_UTMS } from '@/constants/links'
import { Plan } from '@/types'

export type plansObjectType = {
  [key in Plan]: {
    planName: string
    description: string
    relugationHref?: RetainQueryLinkProps['href']
  }
}

export const plansObject: plansObjectType = {
  pro: {
    planName: 'Pro Ton',
    description: 'Venda com as menores taxas do Brasil',
    relugationHref: LINKS_THAT_RETAIN_UTMS.TONPRO_REGULATION
  },
  ultra: {
    planName: 'Ultra Ton',
    description: 'Pra quem vende muito à vista',
    relugationHref: LINKS_THAT_RETAIN_UTMS.MEGATON_REGULATION
  },
  mega: {
    planName: 'Mega Ton',
    description: 'Pra quem vende muito à vista',
    relugationHref: LINKS_THAT_RETAIN_UTMS.MEGATON_REGULATION
  },
  super: {
    planName: 'Super Ton',
    description: 'Máquinas com os menores preços'
  }
}

export const planBBB24Config = {
  userTag: 'promoton_tier',
  machinesName: 'PROMOTON_TIER_',
  planPromoName: 'promoton_tier_'
}
