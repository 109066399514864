import React, { ReactNode } from 'react'

import { CatalogProvider } from '@/contexts/catalog'
import { InfoProvider } from '@/contexts/info'
import { MachinesProvider } from '@/contexts/machines'
import { PlansProvider } from '@/contexts/plans'
import { QRCodeDrawerProvider } from '@/contexts/qrcodeDrawer'
import { VideoPlayerProvider } from '@/contexts/videoPlayer'
import { PlanFee, PlanMachines } from '@/types'
import { InformationsMachine } from '@/types/machines'
import { InformationsPlan, ITapTon } from '@/types/plans'
import { CountdownsContextProvider } from '@/contexts/countdowns'

type DefaultLayoutProps = {
  machinePlans: InformationsPlan[]
  smartphonePlans: InformationsPlan[]
  machineDevices: InformationsMachine[]
  plansMachines: PlanMachines[]
  planFees: PlanFee[]
  tapton: ITapTon
  children: ReactNode
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  machinePlans,
  smartphonePlans,
  machineDevices,
  children,
  plansMachines,
  planFees,
  tapton
}) => {
  return (
    <InfoProvider planFees={planFees} tapton={tapton}>
      <MachinesProvider plansMachines={plansMachines}>
        <PlansProvider
          machinePlans={machinePlans}
          smartphonePlans={smartphonePlans}
        >
          <CatalogProvider machineDevices={machineDevices}>
            <QRCodeDrawerProvider>
              <VideoPlayerProvider>
                <CountdownsContextProvider>
                  {children}
                </CountdownsContextProvider>
              </VideoPlayerProvider>
            </QRCodeDrawerProvider>
          </CatalogProvider>
        </PlansProvider>
      </MachinesProvider>
    </InfoProvider>
  )
}

export default DefaultLayout
