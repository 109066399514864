import { ALL_MACHINES_RESOURCE } from '@/resources/machines/devices'
import { ReceivingOptions, ReceivingOptionsType } from '@/types/fees'
import {
  InformationsDetailMachineFromAPI,
  InformationsDetailMachineResource,
  MachinesInfoDetails
} from '@/types/machines'

interface MergeMachinesContextParams {
  receivingOptions: ReceivingOptionsType
}

export interface MergeWithMachinesLocalDataProps {
  machineDataFromAPI: InformationsDetailMachineFromAPI
  contextParams: MergeMachinesContextParams
}

// Valor fixo para o parcelamento
const INSTALLMENT_PARCEL = 12

const handleInstallmentValue = (
  machineDataFromAPI: InformationsDetailMachineFromAPI
) => {
  // Calcula o valor parcelado da maquininha
  return (parseFloat(machineDataFromAPI.amount) / INSTALLMENT_PARCEL) // Divide o valor total por 12
    .toFixed(2)
    .replace('.', ',')
}

// Função para criar o link para o checkout
const handleLinkToCheckout = (
  machineDataFromAPI: InformationsDetailMachineFromAPI,
  receivingOptions: ReceivingOptionsType
) => {
  // Se a opção de recebimento for no mesmo dia, a antecipação do usuário é 0. Caso contrário, é 1
  const userAnticipation =
    receivingOptions === ReceivingOptions.SameDay ? '0' : '1'

  return {
    pathname: '/checkout/cart',
    query: {
      userTag: machineDataFromAPI.catalog,
      productId: machineDataFromAPI.id,
      userAnticipation
    }
  }
}

// Função para criar o link para a página do produto
const handleLinkToProduct = (
  machineDataFromLocal: InformationsDetailMachineResource
) => {
  return `/maquininha/${machineDataFromLocal.machineName.toLowerCase()}`
}

export const mergeWithMachinesLocalData = ({
  machineDataFromAPI,
  contextParams
}: MergeWithMachinesLocalDataProps): MachinesInfoDetails => {
  const { receivingOptions } = contextParams

  // Busca os dados da maquininha no array de dados locais
  const machineDataFromLocal =
    ALL_MACHINES_RESOURCE[machineDataFromAPI.catalog]?.find(
      (machineLocal) => machineLocal.id === machineDataFromAPI.id
    ) || ({} as InformationsDetailMachineResource)

  // Retorna os dados da maquininha mesclados com os dados locais
  const product: MachinesInfoDetails = {
    ...machineDataFromAPI,
    ...machineDataFromLocal,
    installmentParcel: INSTALLMENT_PARCEL.toString(),
    installmentValue: handleInstallmentValue(machineDataFromAPI),
    linkToCheckout: handleLinkToCheckout(machineDataFromAPI, receivingOptions),
    linkToProduct: handleLinkToProduct(machineDataFromLocal)
  }

  return product
}
