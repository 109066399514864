import React, { ReactNode, useContext, useMemo } from 'react'
import { isDayWithinRange } from '@/utils/isDayWithinRange'
import useAmplitudeTestAB from '@/hooks/useAmplitudeTestAB'
import AMPLITUDE from '@/constants/amplitude'

const CountdownsContext = React.createContext({} as Context)

export function useCountdownsContext() {
  return useContext(CountdownsContext)
}

export const CountdownsContextProvider = ({
  children
}: {
  children: ReactNode
}) => {
  const countdowns = useAmplitudeTestAB(
    AMPLITUDE.FLAGS.FLAG_TON_COUNTDOWNS,
    true,
    true
  ).payload as CountdownConfig[]

  const countdownsByID = useMemo(() => {
    const map = new Map<string, CountdownConfig>()

    if (!countdowns) return map

    countdowns.forEach((item) => {
      map.set(item.id, item)
    })

    return map
  }, [countdowns])

  const activeCountdowns = useMemo(() => {
    const list = new Set<string>()

    countdownsByID.forEach(({ period }, id) => {
      const isActive = isDayWithinRange({
        start: period.startAt,
        end: period.endAt
      })

      if (isActive) {
        list.add(id)
      }
    })

    return list
  }, [countdownsByID])

  return (
    <CountdownsContext.Provider value={{ countdownsByID, activeCountdowns }}>
      {children}
    </CountdownsContext.Provider>
  )
}

interface CountdownConfig {
  id: string
  text?: string
  period: {
    endAt: string
    startAt: string
  }
}

export interface Context {
  activeCountdowns: Set<string>
  countdownsByID: Map<string, CountdownConfig>
}
