import { RECEIVING_OPTIONS_CONDITIONS } from '@/constants/fees'
import {
  FeesCondition,
  MerchantDiscountRates,
  PaymentsCardType,
  ReceivingOptions,
  ReceivingOptionsType
} from '@/types/fees'
import {
  CaptureMethod,
  CardBrand,
  FeeCondition,
  Mdr,
  PaymentMethod
} from '@/types/plans'

const getMdrByFullMatch = (
  fees: FeeCondition,
  paymentMethod: PaymentMethod | null,
  cardBrand: CardBrand | null,
  captureMethod: CaptureMethod | null
): Mdr | undefined =>
  fees.mdrs.find(
    (mdr) =>
      mdr.payment_method === paymentMethod &&
      mdr.card_brand === cardBrand &&
      mdr.capture_method === captureMethod
  )

const getMdr = (
  fees: FeeCondition,
  paymentMethod: PaymentMethod,
  cardBrand: CardBrand,
  captureMethod: CaptureMethod = CaptureMethod.ecommerce
): Mdr => {
  let selectedMdr = getMdrByFullMatch(
    fees,
    paymentMethod,
    cardBrand,
    captureMethod
  )

  if (!selectedMdr) {
    selectedMdr = getMdrByFullMatch(fees, paymentMethod, cardBrand, null)
  }

  if (!selectedMdr) {
    selectedMdr = getMdrByFullMatch(fees, paymentMethod, null, captureMethod)
  }

  if (!selectedMdr) {
    selectedMdr = getMdrByFullMatch(fees, paymentMethod, null, null)
  }

  if (!selectedMdr || selectedMdr.installments.length < 1) {
    throw Error(
      'There is no mdr for:' +
        `paymentMethod: ${paymentMethod},` +
        `cardBrand: ${cardBrand}` +
        `captureMethod: ${captureMethod}`
    )
  }
  return selectedMdr
}

const getCreditMdr = (fees: FeeCondition, cardBrand: CardBrand): Mdr =>
  getMdr(fees, PaymentMethod.credit, cardBrand)

export const getSelectedCreditFee = (
  fees: FeeCondition,
  installments: number,
  cardBrand: CardBrand
): number => {
  const creditMdr = getCreditMdr(fees, cardBrand)
  const selectedCreditFee = creditMdr.installments.find(
    (feeByInstallment) => feeByInstallment.installment === installments
  )
  if (!selectedCreditFee) {
    throw Error(`There is no fee for ${installments} installments`)
  }
  return selectedCreditFee.mdr
}

export const getDebitFee = (fees: FeeCondition, cardBrand: CardBrand): number =>
  getMdr(fees, PaymentMethod.debit, cardBrand).installments[0].mdr

// #############################################################################
// Método que retorna as taxas por condições de antecipação
export const getConditionsByAntecipationTerms = (
  conditions: FeesCondition[],
  antecipationTerms: ReceivingOptions
) => {
  const { anticipation_delay, liquidation_type } =
    RECEIVING_OPTIONS_CONDITIONS[antecipationTerms]

  return conditions.find((condition) => {
    const isSameDelay = condition.anticipation_delay === anticipation_delay
    const isSameLiquidationType =
      condition.liquidation_type === (liquidation_type as string)

    return isSameDelay && isSameLiquidationType
  }) as FeesCondition
}

// Método que retorna as taxas por bandeiras de cartão
export const getFeesByPaymentCard = (
  condition: FeeCondition,
  paymentCard: PaymentsCardType
) => {
  return condition.mdrs.filter(
    (mdr) => mdr.card_brand === paymentCard
  ) as MerchantDiscountRates[]
}
// Método que formata a taxa para string
export const formatFeeToString = (fee: number): string => {
  return fee?.toFixed(2).toString().replace('.', ',').concat('%')
}

// Método que formata a moeda para string
export const formatValueToString = (value: number): string => {
  return `R$ ${value.toFixed(2).toString().replace('.', ',')}`
}

// Método que formata as taxas por grupo de bandeiras
export const formatFeesFromFlagsGroup = (
  mainFlags: MerchantDiscountRates[],
  otherFlags: MerchantDiscountRates[]
) => {
  const mainFlagsFormatted = mainFlags.reduce((acc, flag) => {
    if (flag.payment_method === 'debit_card') {
      acc[0] = flag.installments[0].mdr as number
    }

    if (flag.payment_method === 'credit_card') {
      flag.installments.forEach((installment) => {
        acc[installment.installment] = installment.mdr as number
      })
    }

    return acc
  }, [] as number[])

  const otherFlagsFormatted = otherFlags.reduce((acc, flag) => {
    if (flag.payment_method === 'debit_card') {
      acc[0] = flag.installments[0].mdr as number
    }

    if (flag.payment_method === 'credit_card') {
      flag.installments.forEach((installment) => {
        acc[installment.installment] = installment.mdr as number
      })
    }

    return acc
  }, [] as number[])

  return {
    mainFlags: mainFlagsFormatted,
    otherFlags: otherFlagsFormatted
  }
}

////////////////////////////////////////
// Método que retorna as taxas por condições de antecipação
export const getFeesConditionsByReceivingOptions = (
  conditions: FeesCondition[],
  receivingOptions: ReceivingOptionsType
) => {
  const { anticipation_delay, liquidation_type } =
    RECEIVING_OPTIONS_CONDITIONS[receivingOptions]

  return conditions.find((condition) => {
    const isSameDelay = condition.anticipation_delay === anticipation_delay
    const isSameLiquidationType =
      condition.liquidation_type === (liquidation_type as string)

    return isSameDelay && isSameLiquidationType
  }) as FeesCondition
}

// Método que retorna as taxas por bandeiras de cartão
export const getFeesByPaymentsCard = (
  condition: FeesCondition,
  paymentCard: PaymentsCardType
) => {
  return condition.mdrs.filter(
    (mdr) => mdr.card_brand === paymentCard
  ) as MerchantDiscountRates[]
}

// Método que formata as taxas por grupo de bandeiras
export const formatFeesByFlagsGroup = (
  mainFlags: MerchantDiscountRates[],
  otherFlags: MerchantDiscountRates[]
) => {
  const mainFlagsFormatted = mainFlags.reduce((acc, flag) => {
    if (flag.payment_method === 'debit_card') {
      acc[0] = flag.installments[0].mdr as number
    }

    if (flag.payment_method === 'credit_card') {
      flag.installments.forEach((installment) => {
        acc[installment.installment] = installment.mdr as number
      })
    }

    return acc
  }, [] as number[])

  const otherFlagsFormatted = otherFlags.reduce((acc, flag) => {
    if (flag.payment_method === 'debit_card') {
      acc[0] = flag.installments[0].mdr as number
    }

    if (flag.payment_method === 'credit_card') {
      flag.installments.forEach((installment) => {
        acc[installment.installment] = installment.mdr as number
      })
    }

    return acc
  }, [] as number[])

  return {
    mainFlags: mainFlagsFormatted,
    otherFlags: otherFlagsFormatted
  }
}
