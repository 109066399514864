import { FeesCondition } from './fees'

export enum PlanTypeName {
  tonsuper_tier = 'tonsuper_set24',
  tonultra_tier = 'tonultra_set24',
  megaton_tier_regular = 'megaton_tier_regular',
  promoton_tier_regular = 'promoton_tier_regular_abr24',
  promoton_tier_promo = 'promoton_tier_promo_abr24'
}

export const AVAILABLE_PLAN_TYPES = [
  PlanTypeName.tonsuper_tier,
  PlanTypeName.tonultra_tier,
  PlanTypeName.megaton_tier_regular,
  PlanTypeName.promoton_tier_regular,
  PlanTypeName.promoton_tier_promo
] as const

export type AvailablePlanTypes =
  | PlanTypeName.tonsuper_tier
  | PlanTypeName.tonultra_tier
  | PlanTypeName.megaton_tier_regular
  | PlanTypeName.promoton_tier_regular
  | PlanTypeName.promoton_tier_promo

export interface PlanInfo {
  name: PlanTypeName
  commercial_name: string
  base_plan?: PlanTypeName
  conditions: FeeCondition[]
  type: string
  /**
   * this property is only available when type equals to upgrade
   */
  product?: {
    id: string
    amount: string
  }
}

export interface FeeByInstallment {
  payment_fixed_fee?: number
  installment: number
  mdr: number
}

export enum PaymentMethod {
  credit = 'credit_card',
  debit = 'debit_card'
}

export enum CaptureMethod {
  emv = 'emv',
  ecommerce = 'ecommerce'
}

export type CardBrand = 'elo' | 'mastercard' | 'amex' | 'visa' | 'hipercard'

export type LiquidationType = 'business-day' | 'daily'

export interface Mdr {
  installments: FeeByInstallment[]
  payment_method: PaymentMethod
  capture_method: CaptureMethod | null
  card_brand: CardBrand
}

export interface FeeCondition {
  mdrs: Mdr[]
  anticipation: number
  anticipation_delay: number
  plan_name: PlanTypeName
  commercial_name: string
  liquidation_type: LiquidationType
}

export interface ITapTon {
  best_rate_condition: boolean
  commercial_name: string
  conditions: FeeCondition[]
  name: string
  type: string
}

// ######################################################
// Planos disponíveis para maquininhas
export enum AllPlansForMachine {
  Pro = 'pro',
  Ultra = 'ultra',
  Super = 'super',
  Mega = 'mega'

  // Promo = 'promo',
  // Basic = 'basic'
}

// Tipagem para os planos disponíveis para maquininhas
export type AllPlansForMachineType = `${AllPlansForMachine}`

// Planos disponíveis para smartphones
export enum AllPlansForSmartphone {
  Tapton = 'tapton'
}

// Tipagem para os planos disponíveis para smartphones
export type AllPlansForSmartphoneType = `${AllPlansForSmartphone}`

// Tipagem para todos os planos disponíveis
export type AllPlansType = AllPlansForMachineType | AllPlansForSmartphoneType

// Variações (tiers) de planos disponíveis para maquininhas
export enum AllTiersForMachine {
  // Tonbase = 'tonbase_tier_jul24',
  MegatonRegular = 'megaton_tier_regular',
  // PromotonBase = 'promoton_tier_base_jul24',
  // PromotonPlus = 'promoton_tier_plus_jul24',
  // PromotonRegular = 'promoton_tier_regular_jul24',
  // PromotonPromo = 'promoton_tier_promo_jul24',

  TonSuper = 'tonsuper_set24',
  TonUltra = 'tonultra_set24',
  TonProPromo = 'tonpro_promo_set24',
  TonProBase = 'tonpro_base_set24',
  TonProRegular = 'tonpro_regular_set24',
  TonProPlus = 'tonpro_plus_set24'
}

// Tipagem para as variações (tiers) de planos disponíveis para maquininhas
export type AllTiersForMachineType = `${AllTiersForMachine}`

// Variações (tiers) de planos disponíveis para smartphones
export enum AllTiersForSmartphone {
  Tapton = 'tap'
}

// Tipagem para as variações (tiers) de planos disponíveis para smartphones
export type AllTiersForSmartphoneType = `${AllTiersForSmartphone}`

// Tipagem para todos as variações (tiers) de planos disponíveis
export type AllTiersType = AllTiersForMachineType | AllTiersForSmartphoneType

// Tipagem para as informações comuns sobre os planos
export type AllPlansCommonInformation = {
  [key in AllPlansType]: {
    popularName: string
    shortPopularName: string
    description: string
  }
}

// Opções para liquidação dos recebíveis
export enum Liquidations {
  BusinessDay = 'business-day',
  Daily = 'daily'
}

// Tipagem para as opções de liquidação dos recebíveis
export type LiquidationsType = `${Liquidations}`

// TODO: Rever sobre esse enum, usado no componente NativeDropdown
export enum PromotionsTiersForNativeDropdown {
  TonProPromo = 0,
  TonProBase = 1,
  TonProRegular = 2,
  TonProPlus = 3
}

// Informações do Plano
export interface InformationsPlan {
  planName: AllPlansType
  details: InformationsDetailPlan
}

// Detalhes do Plano
export interface InformationsDetailPlan {
  allowed_liquidation_types: Liquidations[]
  commercial_name: string
  name: AllTiersType
  conditions: FeesCondition[]
  description: string
}

export const PlanTypeNameToPlan: Record<
  AvailablePlanTypes,
  AllPlansForMachine
> = {
  [PlanTypeName.tonsuper_tier]: AllPlansForMachine.Super,
  [PlanTypeName.tonultra_tier]: AllPlansForMachine.Ultra,
  [PlanTypeName.megaton_tier_regular]: AllPlansForMachine.Mega,
  [PlanTypeName.promoton_tier_regular]: AllPlansForMachine.Pro,
  [PlanTypeName.promoton_tier_promo]: AllPlansForMachine.Pro
}
