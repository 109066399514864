import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import {
  AllMachines,
  AllMachinesWithFamilhaoDoTon,
  InformationsMachine,
  MachinesInfo,
  MachinesInfoDetails
} from '@/types/machines'
import { AllPlansForMachine, AllPlansForSmartphone } from '@/types/plans'
import { fetchReferrerMetadata } from '@/utils/fetchReferrerMetadata'
import {
  mergeWithMachinesLocalData,
  MergeWithMachinesLocalDataProps
} from '@/utils/mergeWithMachinesLocalData'
import { getAndSaveSearchParams } from '@/utils/searchParams'

import { usePlans } from './plans'

interface CatalogProviderProps {
  machineDevices: InformationsMachine[]
  children: ReactNode
}

type CatalogContextType = {
  machinesFromPlanSuper: MachinesInfo
  machineFromPlan: MachinesInfo
  couponPercentApplied?: number
  familhaoDoTonPerMachine: AllMachinesWithFamilhaoDoTon // TODO: Remover após a término da campanha Familhão do Ton
  setFamilhaoDoTonPerMachine: (value: AllMachinesWithFamilhaoDoTon) => void // TODO: Remover após a término da campanha Familhão do Ton
}

// ID de maquininhas que não devem receber desconto de cupom
const MACHINES_IDS_WITHOUT_DISCOUNT = ['TONSUPER_D150']

// TODO: Remover após a término da campanha Familhão do Ton
const FAMILHAO_DO_TON_PER_MACHINE: AllMachinesWithFamilhaoDoTon = {
  [AllMachines.T1]: {
    hasFamilhaoDoTon: false
  },
  [AllMachines.T2]: {
    hasFamilhaoDoTon: false
  },
  [AllMachines.T3]: {
    hasFamilhaoDoTon: false
  },
  [AllMachines.T3Smart]: {
    hasFamilhaoDoTon: false
  }
}

const CatalogContext = createContext<CatalogContextType>(
  {} as CatalogContextType
)

export function useCatalog() {
  return useContext(CatalogContext)
}

export const CatalogProvider: React.FC<CatalogProviderProps> = ({
  machineDevices,
  children
}) => {
  const { plan, receivingOptions } = usePlans()

  // TODO: Remover após a término da campanha Familhão do Ton
  const [familhaoDoTonPerMachine, setFamilhaoDoTonPerMachine] = useState(
    FAMILHAO_DO_TON_PER_MACHINE
  )
  const [couponPercentApplied, setCouponPercentApplied] = useState<
    number | undefined
  >()

  const { executeRecaptcha } = useGoogleReCaptcha()

  async function getRecaptchaToken() {
    if (!executeRecaptcha) return

    const token = await executeRecaptcha()

    if (!token) return

    return token
  }

  const checkCoupon = useCallback(
    async (value: string) => {
      const recaptchaToken = await getRecaptchaToken()

      if (!recaptchaToken) return

      const response = await fetchReferrerMetadata({
        coupon: value,
        deviceToken: recaptchaToken
      })

      if (response?.offer?.value)
        setCouponPercentApplied(Number.parseInt(response.offer.value))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeRecaptcha]
  )

  useEffect(() => {
    const searchParams = getAndSaveSearchParams()
    if (
      searchParams &&
      ('coupon' in searchParams || 'referrer' in searchParams)
    ) {
      checkCoupon(searchParams.coupon || searchParams.referrer)
    }
  }, [checkCoupon, executeRecaptcha])

  const [planMachineSelected, setPlanMachineSelected] = useState(
    plan === AllPlansForSmartphone.Tapton ? AllPlansForMachine.Pro : plan
  ) // Se o plano selecionado for Tapton, inicializa com Ton Pro. Caso contrário, inicializa com o plano selecionado

  // Mapeia os dispositivos de máquina para o formato esperado pelo componente
  const allMachines = useMemo(() => {
    const machinesInfo = [] as MachinesInfo[]

    for (const machineDevice of machineDevices) {
      const planName = machineDevice.planName

      const machineProducts = machineDevice.products

      const devices = machineProducts.map<MachinesInfoDetails>((device) => {
        let machine = device

        // Verifica se a máquina deve receber desconto de cupom
        const machineMustHaveCouponDiscount =
          !MACHINES_IDS_WITHOUT_DISCOUNT.includes(machine.id)

        // Se o cupom estiver aplicado e a máquina não for uma das exceções, aplica o desconto
        if (couponPercentApplied && machineMustHaveCouponDiscount) {
          const oldAmount = parseFloat(machine.amount)
          const discount = (oldAmount * couponPercentApplied) / 100

          const amountWithDiscount = (oldAmount - discount).toFixed(2)

          machine = {
            ...machine,
            amount: amountWithDiscount
          }
        }

        const params: MergeWithMachinesLocalDataProps = {
          machineDataFromAPI: machine,
          contextParams: { receivingOptions }
        }

        return mergeWithMachinesLocalData(params)
      })

      machinesInfo.push({
        planName,
        machines: devices
      })
    }

    return machinesInfo
  }, [couponPercentApplied, machineDevices, receivingOptions])

  // Retorna as maquininhas do plano selecionado
  const machineFromPlan = useMemo(() => {
    const machineFromPlan = allMachines.find(
      (machine) => machine.planName === planMachineSelected
    )

    if (!machineFromPlan) {
      throw new Error(
        'Machine not found. Please, check if the plan is correct.'
      )
    }

    // TODO: Remover após a término da campanha Familhão do Ton
    // Aplica incremento de R$ 12,00 no valor das máquinas que teve o Familhão do Ton ativado e atualiza o link para o checkout
    const machines = machineFromPlan.machines.map((machine) => {
      const hasFamilhaoDoTon =
        familhaoDoTonPerMachine[machine.machineName].hasFamilhaoDoTon

      if (hasFamilhaoDoTon) {
        const familhaoDoTonPrice = 12
        const installmentParcel = parseInt(machine.installmentParcel)
        const linkToCheckout = machine.linkToCheckout

        const oldAmount = parseFloat(machine.amount)
        const oldInstallmentValue = parseFloat(
          machine.installmentValue.replace(',', '.')
        )

        const amountWithFamilhao = (oldAmount + familhaoDoTonPrice).toFixed(2)
        const installmentValueWithFamilhao = (
          oldInstallmentValue +
          familhaoDoTonPrice / installmentParcel
        )
          .toFixed(2)
          .replace('.', ',')

        // Atualiza o link para o carrinho
        const newLinkToCheckout = linkToCheckout as unknown as {
          [key: string]: unknown
        }
        const newLinkToCartUnknownParsedQuery = newLinkToCheckout.query

        const newLinkToCart = {
          ...newLinkToCheckout,
          query: {
            ...(newLinkToCartUnknownParsedQuery as unknown as {
              [key: string]: string
            }),
            familhao: true
          }
        }

        machine = {
          ...machine,
          amount: amountWithFamilhao,
          installmentValue: installmentValueWithFamilhao,
          linkToCheckout: newLinkToCart
        }

        return machine
      }

      return machine
    })

    return { ...machineFromPlan, machines }
  }, [allMachines, familhaoDoTonPerMachine, planMachineSelected])

  // Atualiza o plano da máquina selecionada
  useEffect(() => {
    const planMachine =
      plan === AllPlansForSmartphone.Tapton ? AllPlansForMachine.Pro : plan

    setPlanMachineSelected(planMachine)
  }, [plan, setPlanMachineSelected])

  // Retorna as maquininhas do plano Super com coupon/referrer ou sem coupon/referrer aplicado
  const machinesFromPlanSuper = useMemo(() => {
    const machinesFromPlanSuper = allMachines.find(
      (machine) => machine.planName === AllPlansForMachine.Super
    )

    if (!machinesFromPlanSuper) {
      throw new Error(
        'Machine not found. Please, check if the plan is correct.'
      )
    }

    return machinesFromPlanSuper
  }, [allMachines])

  return (
    <CatalogContext.Provider
      value={{
        machinesFromPlanSuper,
        machineFromPlan,
        couponPercentApplied,
        familhaoDoTonPerMachine,
        setFamilhaoDoTonPerMachine
      }}
    >
      {children}
    </CatalogContext.Provider>
  )
}
