import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Machine, Plan, PlanMachines } from '@/types'
import { formatMachinePriceWithCoupon } from '@/utils/coupon'
import { machineHrefWithUserAnticipation } from '@/utils/machineHrefWithUserAnticipation'
import { getAndSaveSearchParams } from '@/utils/searchParams'

import { fetchReferrerMetadata } from '../utils/fetchReferrerMetadata'
import { useInfo } from './info'
import { useMachinesInfo } from '@/hooks/useMachinesInfo'

type MachinesContextProps = {
  plansMachines: PlanMachines[]
  couponPercentApplied?: number
  machines: Machine[]
  getPlanMachines: (plan: Plan) => Machine[]
  children: ReactNode
  machinesInfo: ReturnType<typeof useMachinesInfo>
}

const machinesContextDefaultValues: Omit<MachinesContextProps, 'children'> = {
  plansMachines: [],
  machines: [],
  getPlanMachines: () => [],
  machinesInfo: {} as MachinesContextProps['machinesInfo']
}

const MachinesContext = createContext<Omit<MachinesContextProps, 'children'>>(
  machinesContextDefaultValues
)

export function useMachines() {
  return useContext(MachinesContext)
}

export const MachinesProvider: React.FC<
  Pick<MachinesContextProps, 'plansMachines' | 'children'>
> = (props) => {
  const { plan, paymentTerm } = useInfo()
  const [plansMachines, setPlansMachines] = useState<PlanMachines[]>([])
  const [couponPercentApplied, setCouponPercentApplied] = useState<
    number | undefined
  >()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const getPlanMachines = useCallback(
    (plan: Plan) =>
      plansMachines?.find(({ planName }) => planName === plan)?.machines || [],
    [plansMachines]
  )

  const machines = useMemo(() => getPlanMachines(plan), [getPlanMachines, plan])

  const machinesInfo = useMachinesInfo()

  async function getRecaptchaToken() {
    if (!executeRecaptcha) return

    const token = await executeRecaptcha()

    if (!token) return

    return token
  }

  const checkCoupon = useCallback(
    async (value: string) => {
      const recaptchaToken = await getRecaptchaToken()

      if (!recaptchaToken) return

      const response = await fetchReferrerMetadata({
        coupon: value,
        deviceToken: recaptchaToken
      })

      if (response?.offer?.value)
        setCouponPercentApplied(Number.parseInt(response.offer.value))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeRecaptcha]
  )

  const handlePlansMachines = useCallback(
    (planMachine: PlanMachines[]) =>
      planMachine.map(({ planName, machines }) => ({
        planName,
        machines: machines.map((machine) => ({
          ...machine,
          href: machineHrefWithUserAnticipation(machine.href, paymentTerm)
        }))
      })),
    [paymentTerm]
  )

  useEffect(() => {
    const searchParams = getAndSaveSearchParams()
    if (
      searchParams &&
      ('coupon' in searchParams || 'referrer' in searchParams)
    ) {
      checkCoupon(searchParams.coupon || searchParams.referrer)
    }

    setPlansMachines(handlePlansMachines(props.plansMachines))
  }, [props.plansMachines, checkCoupon, executeRecaptcha, handlePlansMachines])

  useEffect(() => {
    if (couponPercentApplied) {
      setPlansMachines((pm) =>
        pm.map(({ planName, machines }) => ({
          planName,
          machines: machines.map((m) =>
            formatMachinePriceWithCoupon(couponPercentApplied, m)
          )
        }))
      )
    }
  }, [couponPercentApplied, paymentTerm])

  useEffect(() => {
    setPlansMachines((planMachine) => handlePlansMachines(planMachine))
  }, [paymentTerm, handlePlansMachines])
  return (
    <>
      <MachinesContext.Provider
        value={{
          machines,
          machinesInfo,
          plansMachines,
          getPlanMachines,
          couponPercentApplied
        }}
      >
        {props.children}
      </MachinesContext.Provider>
    </>
  )
}
