interface IsDayWithinRangeProps {
  start: string | Date
  end: string | Date
}

export const isDayWithinRange = ({
  start, // '2024-01-01T00:00'
  end // '2024-01-02T00:00'
}: IsDayWithinRangeProps): boolean => {
  const formattedDay = new Date()

  const formattedStart = new Date(start)
  const formattedEnd = new Date(end)

  return formattedDay >= formattedStart && formattedDay <= formattedEnd
}
