import { useContext } from 'react'

import { AmplitudeContext } from '@/contexts/amplitude'

type WaitingFetchVariant = null
type DisabledAmplitudeTest = undefined
type ExperimentVariant = string

export type VariantValue =
  | WaitingFetchVariant
  | DisabledAmplitudeTest
  | ExperimentVariant

type HookResponse = {
  activeVariant: VariantValue
  payload: { [key: string]: [value: string] }[] | []
}

export const useAmplitudeTestAB = (
  experimentId?: string,
  isFlag = false,
  active = true
): HookResponse => {
  const context = useContext(AmplitudeContext)

  if (!context?.useAmplitudeTestAB || !experimentId) {
    return {
      activeVariant: !active ? undefined : null,
      payload: []
    }
  }

  return context.useAmplitudeTestAB(experimentId, isFlag, active)
}

export default useAmplitudeTestAB
