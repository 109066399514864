import { IconsNames } from '@/components/Icon'
import { RetainQueryLinkProps } from '@/components/RetainQueryLink'

import { AllPlansForMachineType } from './plans'

// Definição dos catálogos de maquininhas disponíveis
export enum UserTags {
  Pro = 'tonpro_tier',
  Ultra = 'tonultra',
  Super = 'tonsuper',

  // Promo = 'promoton_tier',
  Mega = 'megaton_tier'
  // Basic = 'tonbase_tier'
}

// Tipagem dos catálogos de maquininhas disponíveis
export type UserTagsType = `${UserTags}`

// Definição das maquininhas
export enum AllMachines {
  T1 = 't1',
  T2 = 't2',
  T3 = 't3',
  T3Smart = 't3-smart'
}

// Tipagem das maquininhas
export type AllMachinesType = `${AllMachines}`

// Definição dos modelos de maquininhas providas pela API
export enum AllMachineModels {
  T1 = 'D150 BLUETOOTH',
  T2 = 'D195',
  T3 = 'S920',
  T3Smart = 'L3'
}

// Tipagem dos modelos de maquininhas providas pela API
export type AllMachineModelsType = `${AllMachineModels}`

// Tipagem dos catálogos de maquininhas providas pela API
export interface InformationsMachine extends InformationsMachineFromAPI {
  planName: AllPlansForMachineType
}

// Tipagem dos detalhes do catálogo de maquininhas providas pela API
export interface InformationsMachineFromAPI {
  products: InformationsDetailMachineFromAPI[]
}

// Tipagem dos detalhes do catálogo de maquininhas providas pela API
export interface InformationsDetailMachineFromAPI {
  id: string
  name: string
  title: string
  amount: string
  original_price: string
  img_url: string
  catalog: UserTagsType
  highlights?: string[]
  cashback_amount: string
  migration_plan: string
  status: string
  workfinity: {
    equipment: string
    model: string
  }
  modifiers: {
    simcards?: 'vivo' | 'tim' | 'claro' | 'microchip claro'
  }
}

export type MachineResourceFeature = {
  icon: IconsNames
  text: string
  className?: string
}

export interface InformationsDetailMachineResource {
  id: string
  machineName: AllMachinesType
  features: MachineResourceFeature[]
  badges?: string[]
}

// Tipagem para as informações das maquininhas
export interface MachinesInfo {
  planName: AllPlansForMachineType
  machines: MachinesInfoDetails[]
}

// Tipagem para os detalhes das informações maquininhas
export interface MachinesInfoDetails
  extends InformationsDetailMachineFromAPI,
    InformationsDetailMachineResource {
  linkToCheckout: RetainQueryLinkProps['href']
  linkToProduct: RetainQueryLinkProps['href']
  installmentValue: string
  installmentParcel: string
}

// Tipagem para as informações das comissões das maquininhas
export type AllMachinesRexCommissions = {
  [key in AllPlansForMachineType]: {
    machines: AllCommissionsMachineDetails[]
  }
}

// Tipagem para os detalhes das comissões de cada maquininhas
export interface AllCommissionsMachineDetails {
  image: string
  name: string
  plan: string
  value: string
}

// Tipagem para as informações comuns sobre os planos
export type AllMachinesCommonInformation = {
  [key in AllMachinesType]: {
    popularName: string
    imageName: {
      left: string
      front: string
      right: string
    }
  }
}

export type AllMachinesWithFamilhaoDoTon = {
  [key in AllMachinesType]: {
    hasFamilhaoDoTon: boolean
  }
}
