import { useRouter } from 'next/router'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import {
  MAIN_FLAGS_DEFAULT,
  OTHER_FLAGS_DEFAULT,
  RECEIVING_OPTIONS_AVAILABLE
} from '@/constants/fees'
import { PROMOTIONAL_DEFAULT_TIER } from '@/constants/plans'
import {
  formatFeesByFlagsGroup,
  getFeesByPaymentsCard,
  getFeesConditionsByReceivingOptions
} from '@/helpers/fees'
import {
  FlagsGroup,
  InformationFeesDetails,
  PlanAndFeesInfo,
  ReceivingOptions
} from '@/types/fees'
import {
  AllPlansForMachine,
  AllPlansType,
  AllTiersForMachine,
  InformationsPlan
} from '@/types/plans'

interface PlansProviderProps {
  machinePlans: InformationsPlan[]
  smartphonePlans: InformationsPlan[]
  children: ReactNode
}

type PlansContextType = {
  plan: AllPlansType
  setPlan: (plan: AllPlansType) => void
  promoTier: AllTiersForMachine
  setPromoTier: (tier: AllTiersForMachine) => void
  flagsGroup: FlagsGroup
  setFlagsGroup: (flagsGroup: FlagsGroup) => void
  receivingOptions: ReceivingOptions
  setReceivingOptions: (receivingOptions: ReceivingOptions) => void
  planAndFeesMachine: PlanAndFeesInfo
  allPlanAndFeesMachine: PlanAndFeesInfo[]
  planAndFeesSmartphone: PlanAndFeesInfo
  allPlanAndFeesSmartphone: PlanAndFeesInfo[]
  plansAndFees: PlanAndFeesInfo
  allPlansAndFees: PlanAndFeesInfo[]
}

const PlansContext = createContext<PlansContextType>({} as PlansContextType)

export function usePlans() {
  return useContext(PlansContext)
}

export const PlansProvider: React.FC<PlansProviderProps> = ({
  machinePlans,
  smartphonePlans,
  children
}) => {
  const router = useRouter()

  const [plan, setPlan] = useState<AllPlansType>(AllPlansForMachine.Pro)
  const [promoTier, setPromoTier] = useState(PROMOTIONAL_DEFAULT_TIER)
  const [flagsGroup, setFlagsGroup] = useState(FlagsGroup.main_flags)
  const [receivingOptions, setReceivingOptions] = useState(
    ReceivingOptions.OneBusinessDay
  )
  // Retorna informações de todos os planos e taxas disponíveis para maquininhas
  const allPlanAndFeesMachine = useMemo(() => {
    const planFeesMachine = [] as PlanAndFeesInfo[]

    for (const plan of machinePlans) {
      const planName = plan.planName
      const tierName = plan.details.name
      const commercialName = plan.details.commercial_name
      const description = plan.details.description as string

      let fees = {} as InformationFeesDetails

      RECEIVING_OPTIONS_AVAILABLE.forEach((option) => {
        const conditions = getFeesConditionsByReceivingOptions(
          plan.details.conditions,
          option
        )

        const mainFlags = getFeesByPaymentsCard(conditions, MAIN_FLAGS_DEFAULT)
        const otherFlags = getFeesByPaymentsCard(
          conditions,
          OTHER_FLAGS_DEFAULT
        )

        const feesObjectMounted: Partial<InformationFeesDetails> = {
          [option]: formatFeesByFlagsGroup(mainFlags, otherFlags)
        }

        fees = { ...fees, ...feesObjectMounted }
      })

      planFeesMachine.push({
        planName,
        tierName,
        commercialName,
        description,
        fees
      })
    }

    return planFeesMachine
  }, [machinePlans])

  // Retorna informações do plano selecionado para uma maquininha
  const planAndFeesMachine = useMemo(() => {
    // Realiza um filtro para encontrar o plano selecionado
    const feesFiltered = allPlanAndFeesMachine.filter(
      (fee) => fee.planName === plan
    ) as PlanAndFeesInfo[]

    // Se o plano for Promo, filtra pelo tier selecionado ou retorna o primeiro plano do array
    const feeFounded =
      plan === AllPlansForMachine.Pro
        ? (feesFiltered.find(
            (fee) => fee.tierName === promoTier
          ) as PlanAndFeesInfo)
        : feesFiltered[0]

    return feeFounded
  }, [allPlanAndFeesMachine, plan, promoTier])

  // Retorna informações de todos os planos e taxas disponíveis para smartphones
  const allPlanAndFeesSmartphone = useMemo(() => {
    const planFeesSmartphone = [] as PlanAndFeesInfo[]

    for (const plan of smartphonePlans) {
      const planName = plan.planName
      const tierName = plan.details.name
      const commercialName = plan.details.commercial_name
      const description = plan.details.description || ''

      let fees = {} as InformationFeesDetails

      RECEIVING_OPTIONS_AVAILABLE.forEach((option) => {
        const conditions = getFeesConditionsByReceivingOptions(
          plan.details.conditions,
          option
        )

        const mainFlags = getFeesByPaymentsCard(conditions, MAIN_FLAGS_DEFAULT)
        const otherFlags = getFeesByPaymentsCard(
          conditions,
          OTHER_FLAGS_DEFAULT
        )

        const feesObjectMounted: Partial<InformationFeesDetails> = {
          [option]: formatFeesByFlagsGroup(mainFlags, otherFlags)
        }

        fees = { ...fees, ...feesObjectMounted }
      })

      planFeesSmartphone.push({
        planName,
        tierName,
        commercialName,
        description,
        fees
      })
    }

    return planFeesSmartphone
  }, [smartphonePlans])

  // TODO: Atualizar lógica quando existir mais de um plano de smartphone
  // Retorna informações do plano selecionado para um smartphone
  const planAndFeesSmartphone = allPlanAndFeesSmartphone[0]

  // Retorna informações de todos os planos e taxas disponíveis (maquininha + smartphone)
  const allPlansAndFees = useMemo(() => {
    return [...allPlanAndFeesMachine, ...allPlanAndFeesSmartphone]
  }, [allPlanAndFeesMachine, allPlanAndFeesSmartphone])

  const plansAndFees = useMemo(() => {
    // Realiza um filtro para encontrar o plano selecionado
    const feesFiltered = allPlansAndFees.filter(
      (fee) => fee.planName === plan
    ) as PlanAndFeesInfo[]

    // Se o plano for Promo, filtra pelo tier selecionado ou retorna o primeiro plano do array
    const feeFounded =
      plan === AllPlansForMachine.Pro
        ? (feesFiltered.find(
            (fee) => fee.tierName === promoTier
          ) as PlanAndFeesInfo)
        : feesFiltered[0]

    return feeFounded
  }, [allPlansAndFees, plan, promoTier])

  // Função auxiliar para selecionar o plano de acordo com a query string
  const updatePlan = (tax: string | undefined) => {
    const planMap: { [key: string]: AllPlansForMachine } = {
      'select-0': AllPlansForMachine.Super,
      'select-1': AllPlansForMachine.Ultra,
      'select-2': AllPlansForMachine.Pro,
      'select-3': AllPlansForMachine.Mega
    }

    if (tax && planMap[tax]) {
      setPlan(planMap[tax])
    }
  }

  // Função auxiliar para selecionar as opções de recebimento de acordo com a query string
  const updateReceivingOptions = (userAnticipation: string | undefined) => {
    const receivingOptionsMap: { [key: string]: ReceivingOptions } = {
      '0': ReceivingOptions.SameDay,
      '1': ReceivingOptions.OneBusinessDay
    }

    if (userAnticipation && receivingOptionsMap[userAnticipation]) {
      setReceivingOptions(receivingOptionsMap[userAnticipation])
    }
  }

  // useEffect para interceptar as query strings e atualizar o estado do plano e opções de recebimento
  useEffect(() => {
    const tax = Array.isArray(router.query.tax)
      ? router.query.tax[0]
      : router.query.tax

    const userAnticipation = Array.isArray(router.query.userAnticipation)
      ? router.query.userAnticipation[0]
      : router.query.userAnticipation

    updatePlan(tax)
    updateReceivingOptions(userAnticipation)
  }, [
    router?.query?.tax,
    router?.query?.userAnticipation,
    setPlan,
    setReceivingOptions
  ])

  return (
    <PlansContext.Provider
      value={{
        plan,
        setPlan,
        promoTier,
        setPromoTier,
        flagsGroup,
        setFlagsGroup,
        receivingOptions,
        setReceivingOptions,
        planAndFeesMachine,
        allPlanAndFeesMachine,
        planAndFeesSmartphone,
        allPlanAndFeesSmartphone,
        plansAndFees,
        allPlansAndFees
      }}
    >
      {children}
    </PlansContext.Provider>
  )
}
