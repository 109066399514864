import {
  AllMachineModels,
  AllMachineModelsType,
  AllMachines,
  AllMachinesCommonInformation,
  AllMachinesType,
  UserTags,
  UserTagsType
} from '@/types/machines'
import { AllPlansForMachine, AllPlansForMachineType } from '@/types/plans'

// #API - Definição dos catálogos de maquininhas disponíveis
export const ALL_USER_TAGS: UserTagsType[] = [
  UserTags.Pro,
  UserTags.Ultra,
  UserTags.Mega,
  UserTags.Super
]

// Mapeamento dos planos por catálogo de maquininhas
export const ALL_USER_TAGS_PLANS_MAPPER: Partial<
  Record<UserTagsType, AllPlansForMachineType>
> = {
  [UserTags.Pro]: AllPlansForMachine.Pro,
  [UserTags.Ultra]: AllPlansForMachine.Ultra,
  [UserTags.Mega]: AllPlansForMachine.Mega,
  [UserTags.Super]: AllPlansForMachine.Super
}

// Objeto com as informações comuns das maquininhas
export const ALL_MACHINES_COMMOM_INFO: AllMachinesCommonInformation = {
  [AllMachines.T1]: {
    popularName: 'T1',
    imageName: {
      front: 'new-t1-1',
      left: 'new-t1-2',
      right: 'new-t1-3'
    }
  },
  [AllMachines.T2]: {
    popularName: 'T2',
    imageName: {
      front: 'new-t2-v2-1',
      left: 'new-t2-v2-2',
      right: 'new-t2-v2-3'
    }
  },
  [AllMachines.T3]: {
    popularName: 'T3',
    imageName: {
      front: 'new-t3-1',
      left: 'new-t3-2',
      right: 'new-t3-3'
    }
  },
  [AllMachines.T3Smart]: {
    popularName: 'T3 Smart',
    imageName: {
      front: 'new-t3-smart-1',
      left: 'new-t3-smart-2',
      right: 'new-t3-smart-3'
    }
  }
}

export const ALL_MACHINES_API_MAPPER: Record<
  AllMachineModelsType,
  AllMachinesType
> = {
  [AllMachineModels.T1]: AllMachines.T1,
  [AllMachineModels.T2]: AllMachines.T2,
  [AllMachineModels.T3]: AllMachines.T3,
  [AllMachineModels.T3Smart]: AllMachines.T3Smart
}
