import { NativeDropdownOptionsProps } from '@/components/NativeDropdown'
import {
  FlagsGroup,
  FlagsGroupCardsType,
  ReceivingOptions,
  ReceivingOptionsCommonInfoType,
  ReceivingOptionsConditionType,
  ReceivingOptionsType
} from '@/types/fees'
import { Liquidations } from '@/types/plans'

export const RECEIVING_OPTIONS_AVAILABLE: ReceivingOptionsType[] = [
  ReceivingOptions.SameDay,
  ReceivingOptions.OneBusinessDay,
  ReceivingOptions.OneMonth
]

// Objeto com as condições de antecipação de recebíveis
export const RECEIVING_OPTIONS_CONDITIONS: ReceivingOptionsConditionType = {
  [ReceivingOptions.SameDay]: {
    anticipation_delay: 0,
    liquidation_type: Liquidations.Daily
  },
  [ReceivingOptions.OneBusinessDay]: {
    anticipation_delay: 1,
    liquidation_type: Liquidations.BusinessDay
  },
  [ReceivingOptions.OneMonth]: {
    anticipation_delay: 30,
    liquidation_type: Liquidations.BusinessDay
  }
}

export const RECEIVING_OPTIONS_COMMON_INFO: ReceivingOptionsCommonInfoType = {
  [ReceivingOptions.SameDay]: {
    label: 'no mesmo dia'
  },
  [ReceivingOptions.OneBusinessDay]: {
    label: '1 dia útil'
  },
  [ReceivingOptions.OneMonth]: {
    label: '30 dias'
  }
}

// Objeto com as devidas bandeiras de seus grupos
export const FLAGS_GROUP_CARDS: FlagsGroupCardsType = {
  mainFlags: ['mastercard', 'visa'],
  otherFlags: ['elo', 'amex', 'hipercard']
}

// Bandeira padrão do grupo de bandeiras principais
export const MAIN_FLAGS_DEFAULT = FLAGS_GROUP_CARDS.mainFlags[0]

// Bandeira padrão do grupo de outras bandeiras
export const OTHER_FLAGS_DEFAULT = FLAGS_GROUP_CARDS.otherFlags[0]

// Array com os grupos de bandeiras para maquininhas disponíveis para uso no componente NativeDropdown
export const nativeDropdownFlagGroupsMachine: NativeDropdownOptionsProps[] = [
  {
    id: 'select-option-machine-flags-group-main',
    value: '0',
    label: 'MasterCard e Visa',
    valueType: FlagsGroup.main_flags
  },
  {
    id: 'select-option-machine-flags-group-other',
    value: '1',
    label: 'Elo, Amex e Hipercard',
    valueType: FlagsGroup.other_flags
  }
]

// Array com os grupos de bandeiras para smartphones disponíveis para uso no componente NativeDropdown
export const nativeDropdownFlagGroupsTapton: NativeDropdownOptionsProps[] = [
  {
    id: 'select-option-tapton-flags-group-main',
    value: '0',
    label: 'MasterCard e Visa',
    valueType: FlagsGroup.main_flags
  },
  {
    id: 'select-option-tapton-flags-group-main',
    value: '1',
    label: 'Elo e Amex',
    valueType: FlagsGroup.other_flags
  }
]

// Array com as opções de bandeiras disponíveis para uso no componente NativeDropdown
export const nativeDropdownSaleType: NativeDropdownOptionsProps[] = [
  { id: 'select-option-debit', value: '0', label: 'Débito' },
  { id: 'select-option-credit-1x', value: '1', label: 'Parcelado 1x' },
  { id: 'select-option-credit-2x', value: '2', label: 'Parcelado 2x' },
  { id: 'select-option-credit-3x', value: '3', label: 'Parcelado 3x' },
  { id: 'select-option-credit-4x', value: '4', label: 'Parcelado 4x' },
  { id: 'select-option-credit-5x', value: '5', label: 'Parcelado 5x' },
  { id: 'select-option-credit-6x', value: '6', label: 'Parcelado 6x' },
  { id: 'select-option-credit-7x', value: '7', label: 'Parcelado 7x' },
  { id: 'select-option-credit-8x', value: '8', label: 'Parcelado 8x' },
  { id: 'select-option-credit-9x', value: '9', label: 'Parcelado 9x' },
  { id: 'select-option-credit-10x', value: '10', label: 'Parcelado 10x' },
  { id: 'select-option-credit-11x', value: '11', label: 'Parcelado 11x' },
  { id: 'select-option-credit-12x', value: '12', label: 'Parcelado 12x' }
]

// Array com as opções de bandeiras disponíveis para uso no componente NativeDropdown
export const nativeDropdownCreditInstallments: NativeDropdownOptionsProps[] = [
  {
    id: 'select-option-installment-2x',
    value: '0',
    label: 'CRÉDITO PARCELADO 2x'
  },
  {
    id: 'select-option-installment-3x',
    value: '1',
    label: 'CRÉDITO PARCELADO 3x'
  },
  {
    id: 'select-option-installment-4x',
    value: '2',
    label: 'CRÉDITO PARCELADO 4x'
  },
  {
    id: 'select-option-installment-5x',
    value: '3',
    label: 'CRÉDITO PARCELADO 5x'
  },
  {
    id: 'select-option-installment-6x',
    value: '4',
    label: 'CRÉDITO PARCELADO 6x'
  },
  {
    id: 'select-option-installment-7x',
    value: '5',
    label: 'CRÉDITO PARCELADO 7x'
  },
  {
    id: 'select-option-installment-8x',
    value: '6',
    label: 'CRÉDITO PARCELADO 8x'
  },
  {
    id: 'select-option-installment-9x',
    value: '7',
    label: 'CRÉDITO PARCELADO 9x'
  },
  {
    id: 'select-option-installment-10x',
    value: '8',
    label: 'CRÉDITO PARCELADO 10x'
  },
  {
    id: 'select-option-installment-11x',
    value: '9',
    label: 'CRÉDITO PARCELADO 11x'
  },
  {
    id: 'select-option-installment-12x',
    value: '10',
    label: 'CRÉDITO PARCELADO 12x'
  }
]

// Array com as opções de antecipação de recebíveis disponíveis para uso no componente NativeDropdown
export const nativeDropdownReceivingOption: NativeDropdownOptionsProps[] = [
  {
    id: 'select-option-receiving-options-sameday',
    value: '0',
    label: 'No mesmo dia',
    valueType: ReceivingOptions.SameDay
  },
  {
    id: 'select-option-receiving-options-oneday',
    value: '1',
    label: '1 dia útil',
    valueType: ReceivingOptions.OneBusinessDay
  }
]
