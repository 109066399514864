import { ALL_MACHINES_COMMOM_INFO } from '@/constants/machines'
import { AllMachines } from '@/types/machines'
import useAmplitudeTestAB from './useAmplitudeTestAB'
import AMPLITUDE from '@/constants/amplitude'

enum ExperimentCRO031 {
  VariantControl = 'control-cro-ton-031',
  VariantB = 'var-b-cro-ton-031'
}

export function useMachinesInfo() {
  const { activeVariant } = useAmplitudeTestAB(AMPLITUDE.TESTS.CRON_TON_031)

  const infos = { ...ALL_MACHINES_COMMOM_INFO }

  if (activeVariant === ExperimentCRO031.VariantB) {
    infos[AllMachines.T3Smart].imageName = {
      front: 'new-t3-smart-gpos-1',
      left: 'new-t3-smart-gpos-2',
      right: 'new-t3-smart-gpos-3'
    }
  }

  return infos
}
