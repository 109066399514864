import { RetainQueryLinkProps } from '@/components/RetainQueryLink'
import { PAYMENT_TERM } from '@/contexts/info'

type HrefMachineProps = {
  pathname: string
  query: {
    productId: string
    userTag: string
    userAnticipation?: PAYMENT_TERM
  }
}

export const machineHrefWithUserAnticipation = (
  href: RetainQueryLinkProps['href'],
  userAnticipation: PAYMENT_TERM
) => {
  let hrefMachine = href as HrefMachineProps

  hrefMachine = {
    ...hrefMachine,
    query: {
      ...hrefMachine.query,
      userAnticipation: userAnticipation
    }
  }

  return hrefMachine as RetainQueryLinkProps['href']
}
