import { Machine } from '@/types'

export const formatMachinePriceWithCoupon = (
  discount: number,
  machine: Machine,
  except = ['TONBASE_TIER_D150']
): Machine => {
  const machinePrice = Number(machine.to)
  const to = (machinePrice - (discount / 100) * machinePrice)
    .toFixed(2)
    .toString()

  const or = (Number(to.replace(',', '.')) / 12)
    .toFixed(2)
    .toString()
    .replace('.', ',')

  return {
    ...machine,
    to: except.includes(machine.id) ? machine.to : to,
    or: except.includes(machine.id) ? machine.or : or
  }
}
