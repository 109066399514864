import {
  AllMachines,
  InformationsDetailMachineResource,
  UserTags
} from '@/types/machines'

// Array com dados complementares das maquininhas
export const ALL_MACHINES_RESOURCE: Partial<
  Record<UserTags, InformationsDetailMachineResource[]>
> = {
  [UserTags.Super]: [
    {
      id: 'TONSUPER_D150',
      machineName: AllMachines.T1,
      badges: ['precisadecelular'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'phone-check',
          text: 'Precisa de celular com internet'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'message-r-chat',
          text: 'Comprovante por SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        }
      ]
    },
    {
      id: 'TONSUPER_D195',
      machineName: AllMachines.T2,
      badges: ['conexao3g', 'comchip'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 3G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'message-r-chat',
          text: 'Comprovante por SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        }
      ]
    },
    {
      id: 'TONSUPER_S920',
      machineName: AllMachines.T3,
      badges: ['combobina', 'comchip'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 3G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'pos-receipt-list',
          text: 'Comprovante impresso ou SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        }
      ]
    },
    {
      id: 'TONSUPER_SMART_POS',
      machineName: AllMachines.T3Smart,
      badges: ['bateria', 'touchscreen'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 4G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'pos-receipt-list',
          text: 'Comprovante impresso ou SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        },
        {
          icon: 'battery-charging',
          text: 'Bateria de longa duração'
        },
        {
          icon: 'mouse-touch-point',
          text: 'Sistema Android com Visor Touchscreen'
        }
      ]
    }
  ],
  [UserTags.Ultra]: [
    {
      id: 'TONULTRA_D150',
      machineName: AllMachines.T1,
      badges: ['precisadecelular'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'phone-check',
          text: 'Precisa de celular com internet'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'message-r-chat',
          text: 'Comprovante por SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        }
      ]
    },
    {
      id: 'TONULTRA_D195',
      machineName: AllMachines.T2,
      badges: ['conexao3g', 'comchip'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 3G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'message-r-chat',
          text: 'Comprovante por SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        }
      ]
    },
    {
      id: 'TONULTRA_S920',
      machineName: AllMachines.T3,
      badges: ['combobina', 'comchip'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 3G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'pos-receipt-list',
          text: 'Comprovante impresso ou SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        }
      ]
    },
    {
      id: 'TONULTRA_SMART_POS',
      machineName: AllMachines.T3Smart,
      badges: ['bateria', 'touchscreen'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 4G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'pos-receipt-list',
          text: 'Comprovante impresso ou SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        },
        {
          icon: 'battery-charging',
          text: 'Bateria de longa duração'
        },
        {
          icon: 'mouse-touch-point',
          text: 'Sistema Android com Visor Touchscreen'
        }
      ]
    }
  ],
  [UserTags.Mega]: [
    {
      id: 'MEGATON_TIER_D150',
      machineName: AllMachines.T1,
      badges: ['precisadecelular'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'phone-check',
          text: 'Precisa de celular com internet'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'message-r-chat',
          text: 'Comprovante por SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        }
      ]
    },
    {
      id: 'MEGATON_TIER_D195',
      machineName: AllMachines.T2,
      badges: ['conexao3g', 'comchip'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 3G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'message-r-chat',
          text: 'Comprovante por SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        }
      ]
    },
    {
      id: 'MEGATON_TIER_S920',
      machineName: AllMachines.T3,
      badges: ['combobina', 'comchip'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 3G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'pos-receipt-list',
          text: 'Comprovante impresso ou SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        }
      ]
    },
    {
      id: 'MEGATON_TIER_SMART_POS',
      machineName: AllMachines.T3Smart,
      badges: ['bateria', 'touchscreen'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 4G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'pos-receipt-list',
          text: 'Comprovante impresso ou SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        },
        {
          icon: 'battery-charging',
          text: 'Bateria de longa duração'
        },
        {
          icon: 'mouse-touch-point',
          text: 'Sistema Android com Visor Touchscreen'
        }
      ]
    }
  ],
  [UserTags.Pro]: [
    {
      id: 'TONPRO_TIER_D150',
      machineName: AllMachines.T1,
      badges: ['precisadecelular'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'phone-check',
          text: 'Precisa de celular com internet'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'message-r-chat',
          text: 'Comprovante por SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        }
      ]
    },
    {
      id: 'TONPRO_TIER_D195',
      machineName: AllMachines.T2,
      badges: ['conexao3g', 'comchip'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 3G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'message-r-chat',
          text: 'Comprovante por SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        }
      ]
    },
    {
      id: 'TONPRO_TIER_S920',
      machineName: AllMachines.T3,
      badges: ['combobina', 'comchip'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 3G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'pos-receipt-list',
          text: 'Comprovante impresso ou SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        }
      ]
    },
    {
      id: 'TONPRO_TIER_SMART_POS',
      machineName: AllMachines.T3Smart,
      badges: ['bateria', 'touchscreen'],
      features: [
        {
          className: 'text-newTon-700',
          icon: 'truck',
          text: 'Frete e troca grátis pra todo o Brasil'
        },
        {
          icon: 'signal-wifi-2',
          text: 'Com Chip 4G e Wi-Fi'
        },
        {
          icon: 'signal-contactless',
          text: 'Receba por aproximação (NFC)'
        },
        {
          icon: 'pos-receipt-list',
          text: 'Comprovante impresso ou SMS'
        },
        {
          icon: 'dollar-phone',
          text: 'Venda pelo App com TapTon, Link, Pix e Boleto'
        },
        {
          icon: 'qr-code-pix',
          text: 'Aceite Pix QR Code na Maquininha'
        },
        {
          icon: 'battery-charging',
          text: 'Bateria de longa duração'
        },
        {
          icon: 'mouse-touch-point',
          text: 'Sistema Android com Visor Touchscreen'
        }
      ]
    }
  ]
}
