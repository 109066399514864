import { NativeDropdownOptionsProps } from '@/components/NativeDropdown'
import {
  AllPlansCommonInformation,
  AllPlansForMachine,
  AllPlansForMachineType,
  AllPlansForSmartphone,
  AllPlansForSmartphoneType,
  AllPlansType,
  AllTiersForMachine,
  AllTiersForMachineType,
  AllTiersForSmartphone,
  AllTiersForSmartphoneType,
  AllTiersType
} from '@/types/plans'

// #API - Array com todos os planos disponíveis para maquininhas
export const ALL_PLANS_FOR_MACHINE: AllPlansForMachineType[] = [
  AllPlansForMachine.Pro,
  AllPlansForMachine.Ultra,
  AllPlansForMachine.Mega,
  AllPlansForMachine.Super
]

// #API - Array com todos os planos disponíveis para smartphones
export const ALL_PLANS_FOR_SMARTPHONE: AllPlansForSmartphoneType[] = [
  AllPlansForSmartphone.Tapton
]

// #API - Array com todos os tiers disponíveis para maquininhas
export const ALL_TIERS_FOR_MACHINE: AllTiersForMachineType[] = [
  AllTiersForMachine.TonSuper,
  AllTiersForMachine.TonUltra,
  AllTiersForMachine.MegatonRegular,
  AllTiersForMachine.TonProBase,
  AllTiersForMachine.TonProPlus,
  AllTiersForMachine.TonProRegular,
  AllTiersForMachine.TonProPromo
]

// #API - Array com todos os tiers disponíveis para smartphones
export const ALL_TIERS_FOR_SMARTPHONE: AllTiersForSmartphoneType[] = [
  AllTiersForSmartphone.Tapton
]
// Array com todos os planos disponíveis (maquininha + smartphone)
export const ALL_PLANS = [...ALL_PLANS_FOR_MACHINE, ...ALL_PLANS_FOR_SMARTPHONE]

// Tier padrão do plano promocional
export const PROMOTIONAL_DEFAULT_TIER = AllTiersForMachine.TonProPromo

// Array com as opções de tiers do plano promocional
export const ALL_PROMOTON_TIER = [
  AllTiersForMachine.TonProPromo,
  AllTiersForMachine.TonProBase,
  AllTiersForMachine.TonProRegular,
  AllTiersForMachine.TonProPlus
]

// Objeto com os tiers por planos mapeados
export const TIER_BY_PLANS: Partial<Record<AllTiersType, AllPlansType>> = {
  [AllTiersForMachine.TonSuper]: AllPlansForMachine.Super,
  [AllTiersForMachine.TonUltra]: AllPlansForMachine.Ultra,
  [AllTiersForMachine.MegatonRegular]: AllPlansForMachine.Mega,
  [AllTiersForMachine.TonProBase]: AllPlansForMachine.Pro,
  [AllTiersForMachine.TonProPlus]: AllPlansForMachine.Pro,
  [AllTiersForMachine.TonProRegular]: AllPlansForMachine.Pro,
  [AllTiersForMachine.TonProPromo]: AllPlansForMachine.Pro,
  [AllTiersForSmartphone.Tapton]: AllPlansForSmartphone.Tapton
}

// Array com os nomes populares dos planos
export const ALL_PLANS_COMMOM_INFO: AllPlansCommonInformation = {
  [AllPlansForMachine.Pro]: {
    popularName: 'Ton Pro',
    shortPopularName: 'Pro',
    description: 'Venda com as menores taxas do Brasil'
  },
  [AllPlansForMachine.Ultra]: {
    popularName: 'Ton Ultra',
    shortPopularName: 'Ultra',
    description: 'Pra quem vende muito à vista'
  },
  [AllPlansForMachine.Mega]: {
    popularName: 'Ton Mega',
    shortPopularName: 'Mega',
    description: 'Pra quem vende muito à vista'
  },
  [AllPlansForMachine.Super]: {
    popularName: 'Ton Super',
    shortPopularName: 'Super',
    description: 'Máquinas com os menores preços'
  },
  [AllPlansForSmartphone.Tapton]: {
    popularName: 'TapTon',
    shortPopularName: 'TapTon',
    description: 'Máquina de cartão grátis no celular'
  }
}

// Array com os tiers disponíveis para uso no componente NativeDropdown
export const nativeDropdownPromotionsTiersMachine: NativeDropdownOptionsProps[] =
  [
    {
      id: 'ton-pro-tier-promotional',
      label: 'Período Promocional',
      value: '0',
      className: '!text-left !py-[3px] !px-[12px] !mt-8',
      description:
        'Taxa válida durante 30 dias ou até atingir R$ 5 mil em vendas',
      valueType: AllTiersForMachine.TonProPromo
    },
    {
      id: 'ton-pro-tier-base',
      label: 'Vendas mensais até R$ 2 mil',
      value: '1',
      className: '!text-left !py-[3px] !px-[12px] !mt-8',
      description:
        'Taxas após período promocional de acordo com suas vendas mensais',
      valueType: AllTiersForMachine.TonProBase
    },
    {
      id: 'ton-pro-tier-regular',
      label: 'Vendas mensais de R$ 2 mil a R$ 20 mil',
      value: '2',
      className: '!text-left !py-[3px] !px-[12px] !mt-8',
      description:
        'Taxas após período promocional de acordo com suas vendas mensais',
      valueType: AllTiersForMachine.TonProRegular
    },
    {
      id: 'ton-pro-tier-plus',
      label: 'Vendas mensais acima de R$ 20 mil',
      value: '3',
      className: '!text-left !py-[3px] !px-[12px] !mt-8',
      description:
        'Taxas após período promocional de acordo com suas vendas mensais',
      valueType: AllTiersForMachine.TonProPlus
    }
  ]

export const nativeDropdownPromotionsTiersMachinePlansAndFees: NativeDropdownOptionsProps[] =
  [
    {
      id: 'promoton-tier-promo',
      label: 'Período Promocional',
      value: '0',
      className: '!text-left !py-[3px] !px-[12px] !mt-8',
      description:
        'Taxa válida durante 30 dias ou até atingir R$ 5 mil em vendas',
      valueType: AllTiersForMachine.TonProPromo
    },
    {
      id: 'promoton-tier-base',
      label: 'Vendas até R$ 2 mil',
      value: '1',
      className: '!text-left !py-[3px] !px-[12px] !mt-8',
      description:
        'Taxas após período promocional de acordo com suas vendas mensais',
      valueType: AllTiersForMachine.TonProBase
    },
    {
      id: 'promoton-tier-regular',
      label: 'Vendas R$ 2 mil a R$ 20 mil',
      value: '2',
      className: '!text-left !py-[3px] !px-[12px] !mt-8',
      description:
        'Taxas após período promocional de acordo com suas vendas mensais',
      valueType: AllTiersForMachine.TonProRegular
    },
    {
      id: 'promoton-tier-plus',
      label: 'Vendas acima de R$ 20 mil',
      value: '3',
      className: '!text-left !py-[3px] !px-[12px] !mt-8',
      description:
        'Taxas após período promocional de acordo com suas vendas mensais',
      valueType: AllTiersForMachine.TonProPlus
    }
  ]
